import { Dialog, DialogContent, IconButton } from "@suraasa/placebo-ui"
import { Cancel } from "iconoir-react"
import { useState } from "react"

import ImageViewer from "./ImageViewer"

type Props = {
  url: string | undefined
  className?: string
  children: React.ReactNode
}

export const isImage = (url: string | undefined | null) => {
  if (!url) return false

  const path = new URL(url).pathname
  const ext = path.split(".").reverse()[0]
  return ext
    ? ["jpg", "jpeg", "png", "webp"].includes(ext.toLowerCase())
    : false
}

const FileViewer = ({ url, className, children }: Props) => {
  const [showViewer, setShowViewer] = useState(false)

  if (!url) {
    return null
  }

  if (!isImage(url)) {
    throw new Error("the supplied url is not an image")
  }

  return (
    <>
      <button
        className={className}
        onClick={() => {
          setShowViewer(true)
        }}
      >
        {children}
      </button>
      <Dialog
        fullScreen={true}
        open={showViewer}
        onRequestClose={() => {
          setShowViewer(false)
        }}
      >
        <DialogContent className="!p-0">
          <ImageViewer url={url} />
        </DialogContent>
        <div className="absolute right-2 top-2">
          <IconButton color="black" className="bg-white">
            <Cancel
              onClick={() => {
                setShowViewer(false)
              }}
            />
          </IconButton>
        </div>
      </Dialog>
    </>
  )
}

export default FileViewer
